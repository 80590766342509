import { db } from '@/shared/firebase'

const CalculateOrderStatistic = async orderId => {
  const getEmptyStatsObject = type => {
    const statsObject = {
      numberOfItems: 0,
      revenue: {
        product: {
          net: 0,
          gross: 0,
          tax7: 0,
          tax19: 0
        },
        deliveryFee: {
          net: 0,
          gross: 0,
          tax7: 0,
          tax19: 0
        },
        total: {
          net: 0,
          gross: 0,
          tax7: 0,
          tax19: 0
        }
      },
      products: {}
    }
    if (type === 'resident') statsObject.numberOfResidents = 0
    return statsObject
  }
  const calculateShippingCosts = shipments => {
    let shipmentCost = 0
    const priceTable = {
      1: 3.55,
      2: 3.67,
      3: 3.9,
      5: 4.36,
      7: 4.82,
      10: 5.51,
      15: 5.69,
      20: 5.69,
      ['31.5']: 6.07
    }
    const environmentalPremium = 0.11

    shipments.forEach(shipment => {
      for (const [weight, price] of Object.entries(priceTable)) {
        if (shipment.weightInKG <= weight) {
          shipmentCost += price + environmentalPremium
          break
        }
      }
    })

    return parseFloat(shipmentCost.toFixed(2))
  }
  const sumObjects = (sumObject, statObject) => {
    sumObject.numberOfItems += statObject.numberOfItems
    if (statObject.numberOfResidents)
      sumObject.numberOfResidents = (sumObject.numberOfResidents || 0) + statObject.numberOfResidents
    Object.keys(sumObject.revenue).forEach(revenueType => {
      Object.keys(sumObject.revenue[revenueType]).forEach(taxType => {
        sumObject.revenue[revenueType][taxType] = parseFloat(
          (sumObject.revenue[revenueType][taxType] + statObject.revenue[revenueType][taxType] || 0).toFixed(2)
        )
      })
    })
    Object.keys(statObject.products).forEach(productId => {
      const amount = statObject.products[productId]
      sumObject.products[productId] = (sumObject.products[productId] || 0) + amount
    })
    return sumObject
  }
  const order = await db
    .doc(`orders/${orderId}`)
    .get()
    .then(doc => doc.data())
  const invoices = await Promise.all(
    order.invoiceIds.map(invoiceId => {
      return db
        .doc(`invoices/${invoiceId}`)
        .get()
        .then(doc => {
          return { ...doc.data(), id: doc.id }
        })
    })
  )
  const statistic = {
    shippingCosts: order.shipments ? calculateShippingCosts(order.shipments) : 0,
    sum: getEmptyStatsObject()
  }
  invoices.forEach(invoice => {
    const statType = invoice.type.replace('Invoice', '')
    const revenueTypes = ['deliveryFee', 'total']
    const taxTypes = ['net', 'gross', 'tax7', 'tax19']
    if (!statistic[statType]) statistic[statType] = getEmptyStatsObject(statType)
    revenueTypes.forEach(revenueType => {
      const sumsKey = revenueType === 'total' ? 'sums' : 'deliveryFeeSums'
      statistic[statType].revenue[revenueType].gross += invoice[sumsKey].gross.total
      statistic[statType].revenue[revenueType].net += invoice[sumsKey].net.total
      statistic[statType].revenue[revenueType].tax7 += invoice[sumsKey].tax[7]
      statistic[statType].revenue[revenueType].tax19 += invoice[sumsKey].tax[19]
    })
    statistic[statType].numberOfItems += invoice.positions.reduce((a, b) => {
      return a + b.amount
    }, 0)
    invoice.positions.forEach(pos => {
      const { amount, productId } = pos
      statistic[statType].products[productId] = (statistic[statType].products[productId] || 0) + amount
    })
    if (statType === 'resident') statistic[statType].numberOfResidents++
    revenueTypes.forEach(revenueType => {
      taxTypes.forEach(taxType => {
        statistic[statType].revenue[revenueType][taxType] = parseFloat(
          statistic[statType].revenue[revenueType][taxType].toFixed(2)
        )
        statistic[statType].revenue.product[taxType] = parseFloat(
          (statistic[statType].revenue.total[taxType] - statistic[statType].revenue.deliveryFee[taxType]).toFixed(2)
        )
      })
    })
  })
  ;['resident', 'homeOrder', 'kiosk'].forEach(statType => {
    if (statistic[statType]) statistic.sum = sumObjects(statistic.sum, statistic[statType])
  })

  console.log(statistic)

  // await db.doc(`orders/${orderId}`).update({ statistic })
}

export default CalculateOrderStatistic
