<template>
  <BaseLoader v-if="!$store.getters.isLoaded(['organizations'])" />
  <div v-else>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h1 class="h2 mb-0">{{ organization.name }}</h1>
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-outline-primary mr-3" @click="openOrganizationResident">
          Show Residents
        </button>
        <DotMenu>
          <li @click="loginAsOrganization">Login as Organization</li>
          <!-- <li @click="getUnpaidInvoicesReport">Get Unpaid Invoices Report</li> -->
          <li @click="calculateOrderStatistics">Calculate Statistics</li>
          <li @click="getUnpaidInvoicesReport">Download Open Invoices Table</li>
          <!-- <li @click="deleteOrganization" class="text-danger">Delete Organization</li> -->
        </DotMenu>
      </div>
    </div>

    <div class="mb-4">
      <div class="custom-control-inline custom-switch">
        <input
          type="checkbox"
          class="custom-control-input"
          id="features-delivery-service"
          :checked="organization.features.deliveryService"
          @change="toggleFeature('deliveryService')"
          :disabled="$store.state.role !== 'admin'"
        />
        <label class="custom-control-label" for="features-delivery-service">Delivery Service</label>
      </div>
      <div class="custom-control-inline custom-switch">
        <input
          type="checkbox"
          class="custom-control-input"
          id="features-kiosk"
          :checked="organization.features.kiosk"
          @change="toggleFeature('kiosk')"
          :disabled="$store.state.role !== 'admin'"
        />
        <label class="custom-control-label" for="features-kiosk">Kiosk</label>
      </div>
    </div>

    <div class="d-flex mb-5 w-100" style="font-family: monospace">
      <div v-if="organization.billingAddress" style="flex: 1 1 0px">
        <strong>Billing Address:</strong><br />
        {{ organization.billingAddress.name }}<br />
        {{ organization.billingAddress.name2 }}<br />
        {{ organization.billingAddress.street }} {{ organization.billingAddress.streetNumber }}<br />
        {{ organization.billingAddress.zip }} {{ organization.billingAddress.city }}
      </div>
      <div v-else style="flex: 1 1 0px">No Shipping Address</div>
      <div v-if="organization.shippingAddress" style="flex: 1 1 0px">
        <strong>Shipping Address:</strong><br />
        {{ organization.shippingAddress.name }}<br />
        {{ organization.shippingAddress.name2 }}<br />
        {{ organization.shippingAddress.street }} {{ organization.shippingAddress.streetNumber }}<br />
        {{ organization.shippingAddress.zip }} {{ organization.shippingAddress.city }}
      </div>
      <div v-else style="flex: 1 1 0px">No Billing Address</div>
      <div v-if="organization.payment">
        <strong v-if="organization.payment.method === 'invoice'" style="flex: 1 1 0px">Pay by Inovice</strong>
        <div v-else-if="organization.payment.method === 'sepa'" style="flex: 1 1 0px">
          <strong>Pay by SEPA</strong> <br />Account Holder: {{ organization.payment.bankDetails.accountHolder }}.
          <br />IBAN: {{ organization.payment.bankDetails.iban }}<br />BIC: {{ organization.payment.bankDetails.bic }}
        </div>
      </div>
      <div v-else style="flex: 1 1 0px">No Billing Information</div>
    </div>

    <div v-if="organization.contactPersons" class="d-flex mb-5 w-100" style="font-family: monospace">
      <div v-for="(contactPerson, i) in organization.contactPersons" :key="i" style="flex: 1 1 0px">
        <strong
          ><span v-if="contactPerson.gender === 'female'">Frau</span
          ><span v-if="contactPerson.gender === 'male'">Herr</span> {{ contactPerson.firstName }}
          {{ contactPerson.lastName }}
          <span class="badge badge-secondary ml-1" v-if="contactPerson.qualifiedForAccounting">Accounting</span>
          <span class="badge badge-primary ml-1" v-if="contactPerson.qualifiedForOrders">Orders</span>
        </strong>
        <br />
        <span v-if="contactPerson.phone"
          ><a :href="`tel:${contactPerson.phone}`">{{ contactPerson.phone }}</a
          ><br
        /></span>
        <span v-if="contactPerson.email"
          ><a :href="`mailto:${contactPerson.email}`">{{ contactPerson.email }}</a></span
        >
      </div>
    </div>

    <div v-if="$store.state.role === 'admin'">
      <h2 class="h3 mb-4">Orders</h2>
      <TableSkeleton v-if="orders === null" />
      <div v-else v-for="(month, monthString) in ordersByMonth" :key="monthString">
        <h3 class="h4 ml-2 mb-3">
          {{ `${(parseFloat(monthString.substr(5, 2)) + 1).toString().padStart(2, 0)}/${monthString.substr(0, 4)}` }}
        </h3>
        <OrdersList :orders="month.orders" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { auth, functions, env, db } from '@/shared/firebase'
import DotMenu from '@/shared/DotMenu'
import OrdersList from '@/components/OrdersList'
import TableSkeleton from '@/components/TableSkeleton'
import CalculateOrderStatistic from '@/helpers/CalculateOrderStatistic'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

export default {
  components: { DotMenu, OrdersList, TableSkeleton },
  data() {
    return {
      ordersSubscription: null,
      orders: null,
      loginTokenPending: false
    }
  },
  created() {
    this.ordersSubscription = db
      .collection('orders')
      .where('organizationId', '==', this.organizationId)
      .orderBy('createdAt', 'desc')
      .onSnapshot(snapshot => {
        this.orders = snapshot.docs.map(doc => {
          return { ...doc.data(), id: doc.id }
        })
      })
  },
  computed: {
    organizationId() {
      return this.$route.params.organizationId
    },
    organization() {
      return _.find(this.$store.state.organizations, { id: this.organizationId })
    },
    ordersByMonth: function() {
      if (!Array.isArray(this.orders)) return null
      const orders = this.orders.map(order => {
        const createdAt = order.createdAt.toDate()
        order.monthString = `${moment(createdAt).year()}-${moment(createdAt)
          .month()
          .toString()
          .padStart(2, '0')}`
        order.dateString = moment(createdAt).format('DD.MM.YYYY')
        return order
      })

      const ordersByMonth = _.groupBy(orders, 'monthString')

      Object.keys(ordersByMonth).forEach(monthString => {
        const orders = ordersByMonth[monthString]
        ordersByMonth[monthString] = { orders }
      })

      return ordersByMonth
    }
  },
  methods: {
    openOrganizationResident() {
      this.$router.push({ name: 'organizationResident', params: { organizationId: this.organizationId } })
    },
    toggleFeature(feature) {
      const { features } = this.organization
      features[feature] = !features[feature]
      db.doc(`organizations/${this.organizationId}`).update({ features })
    },
    async calculateOrderStatistics() {
      const orders = await db
        .collection('orders')
        .where('organizationId', '==', this.organizationId)
        .get()
        .then(snapshot => {
          return snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
        })
      const promises = []
      orders
        .filter(o => o.invoiceIds)
        .forEach(order => {
          CalculateOrderStatistic(order.id)
        })
      Promise.all(promises)
    },
    async deleteOrganization() {
      if (confirm('Are you sure?')) {
        if (confirm('Sure sure??')) {
          const deleteOrganization = functions.httpsCallable('deleteOrganization')
          await deleteOrganization({ organizationId: this.organizationId })
            .then(() => {
              this.$router.push('/organizations')
            })
            .catch(err => {
              alert(err.message)
            })
        }
      }
    },
    async getUnpaidInvoicesReport() {
      let invoiceCollections = await db
        .collection('invoiceCollections')
        .where('organizationId', '==', this.organizationId)
        .get()
        .then(snapshot => {
          return snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
        })

      invoiceCollections = invoiceCollections
        .filter(i => i.completelyPaid !== true && i.createdAt.toDate() > new Date('2021-03-01'))
        .sort((a, b) => a.createdAt.toDate().getTime() - b.createdAt.toDate().getTime())

      if (invoiceCollections.length === 0) return alert('No unpaid orders found')

      const promises = []
      invoiceCollections.forEach(invoiceCollection => {
        promises.push(
          db
            .collection('invoices')
            .where('invoiceCollectionId', '==', invoiceCollection.id)
            .get()
            .then(snapshot => {
              return (invoiceCollection.invoices = snapshot.docs.map(doc => {
                return { ...doc.data(), id: doc.id }
              }))
            })
        )
      })
      await Promise.all(promises)

      const workbook = new ExcelJS.Workbook()

      const invoiceCollectionsSheet = workbook.addWorksheet('Sammelauflistungen')
      invoiceCollectionsSheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]
      invoiceCollectionsSheet.columns = [
        { header: 'Sammel-Nr.', key: 'id', width: 15 },
        { header: 'Datum', key: 'date', width: 15 },
        { header: 'Summe', key: 'sum', width: 15 },
        { header: 'Offener Betrag', key: 'unpaidAmount', width: 15 }
      ]

      const invoicesSheet = workbook.addWorksheet('Einzelrechnungen')
      invoicesSheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]
      invoicesSheet.columns = [
        { header: 'Rechnungs-Nr.', key: 'id', width: 15 },
        { header: 'Name', key: 'name', width: 40 },
        { header: 'Datum', key: 'date', width: 15 },
        { header: 'Summe', key: 'sum', width: 15 },
        { header: 'Offener Betrag', key: 'unpaidAmount', width: 15 }
      ]
      ;['sum', 'unpaidAmount'].forEach(key => {
        invoiceCollectionsSheet.getColumn(key).numFmt = '#,##0.00 €'
        invoicesSheet.getColumn(key).numFmt = '#,##0.00 €'
      })

      invoiceCollections.forEach(invoiceCollection => {
        const sum = parseFloat(invoiceCollection.sums.gross.total.toFixed(2))
        let unpaidAmount = sum - (invoiceCollection.paidAmount || 0)
        if (unpaidAmount < 0) unpaidAmount = 0
        if (unpaidAmount === 0) return null
        invoiceCollectionsSheet.addRow({
          id: invoiceCollection.id,
          date: moment(invoiceCollection.createdAt.toDate()).format('DD.MM.YYYY'),
          sum,
          unpaidAmount
        })
        const titleRow = invoicesSheet.addRow({ id: invoiceCollection.id })
        titleRow.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'FFAAAAAA'
          },
          bgColor: {
            argb: 'FF000000'
          }
        }
        invoicesSheet.mergeCells(`A${titleRow._number}:E${titleRow._number}`)
        invoiceCollection.invoices.forEach(invoice => {
          const sum = parseFloat(invoice.sums.gross.total.toFixed(2))
          let unpaidAmount = parseFloat((invoice.sums.gross.total - (invoice.paidAmount || 0)).toFixed(2))
          if (unpaidAmount < 0) unpaidAmount = 0
          const invoiceRow = invoicesSheet.addRow({
            id: invoice.id.replace('_', '/'),
            name: invoice.address.name1,
            date: moment(invoice.date.invoice.toDate()).format('DD.MM.YYYY'),
            sum,
            unpaidAmount
          })
          if (unpaidAmount === 0) {
            invoiceRow.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: 'FFAFD8AF'
              },
              bgColor: {
                argb: 'FF000000'
              }
            }
          }
        })
      })

      const invoiceCollectionsHeaderRow = invoiceCollectionsSheet.getRow(1)
      invoiceCollectionsHeaderRow.font = {
        color: { argb: 'FFFFFF' },
        bold: true
      }
      invoiceCollectionsHeaderRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FF222222'
        },
        bgColor: {
          argb: 'FFFFFFFF'
        }
      }

      const invoicesHeaderRow = invoicesSheet.getRow(1)
      invoicesHeaderRow.font = {
        color: { argb: 'FFFFFF' },
        bold: true
      }
      invoicesHeaderRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FF222222'
        },
        bgColor: {
          argb: 'FFFFFFFF'
        }
      }

      workbook.xlsx.writeBuffer().then(data => {
        var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        saveAs(blob, `Kontoauszug ${this.organization.name} ${moment().format('YYYY-MM-DD')}.xlsx`)
      })
    },
    loginAsOrganization() {
      const hostname =
        window.location.hostname === 'localhost'
          ? 'http://localhost:8080'
          : `https://${env === 'production' ? 'app' : 'app.dev'}.mitemma.de`
      const { uid } = auth.currentUser
      this.loginTokenPending = true
      const getOrganizationToken = functions.httpsCallable('getOrganizationToken')
      getOrganizationToken({
        uid,
        additionalClaims: {
          organizationId: this.organizationId
        }
      })
        .then(result => {
          this.loginTokenPending = false
          if (result.data) {
            window.open(`${hostname}/login?token=${result.data}`)
          }
        })
        .catch(err => {
          this.loginTokenPending = false
          console.warn(err)
          alert('Failed getting login token')
        })
    }
  }
}
</script>
